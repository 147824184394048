import React from 'react';
import { Link } from 'gatsby';
import withContext from 'store/withContext';

// import Button from 'components/Global/Button';

import PageWrapper from '../styles/leagues.css.js';

const Leagues = (props) => {

  return (
    <PageWrapper>
      <main className="content">
        <section className="header">
          <div className="sub-heading">What Is The</div>
          <div className="heading">SMITE CHALLENGER CIRCUIT</div>
          <div className="links-wrapper">
            <Link to="/league">Format & Rules</Link>
            <Link to="/teams/">League Teams</Link>
            <a href="https://twitch.tv/smitegame">Where To Watch</a>
          </div>
        </section>
        <div className="center-wrapper">
          <section className="about">
            <div className="copy-wrapper">
              <h6>ABOUT THE SMITE CHALLENGER CIRCUIT</h6>
              <p>The SMITE Challenger Circuit  is our semi-professional competitive circuit on the Battleground of the Gods. It features 12 aspiring pro teams (6 from NA, 6 from EU) who compete weekly in hopes of grinding their way up to the SMITE Pro League. This is the proving ground where the next generation of SMITE superstars will rise.</p>
              <p>Throughout Season 9, these teams will have the opportunity to qualify for major LAN events like the SMITE Masters, where they’ll go head-to-head against our SPL teams and test their mettle against the best SMITE players in the world.</p>
              <p>At the end of the year, their SCC journey will culminate in a grueling set of qualifiers where they compete for a spot in the SMITE World Championship. Our top-performing SCC teams will also secure a chance to compete in the Season 10 play-in tournament, where they’ll have the opportunity to be promoted into the SPL.</p>
            </div>
            <div className="image-wrapper">
              <img src="https://webcdn.hirezstudios.com/esports/assets/what-is-1.png" alt="SMITE Champions" />
            </div>
          </section>
          <section className="watch">
            <div className="copy-wrapper">
              <h6>WHERE TO WATCH</h6>
              <p>The SMITE Challenger is broadcast weekly on our official SmiteGame streaming channels -- including <a href="https://www.twitch.tv/smitegame" target="_blank">Twitch</a>, <a href="https://www.youtube.com/smitegame" target="_blank">YouTube</a>, and even <a href="" target="_blank">Steam!</a></p>
              <strong>2022 SEASON MATCH SCHEDULE</strong>
              <div className="schedule-container">
                <div><strong>Thursday</strong>Starting at 11:00 AM ET</div>
                <div><strong>Friday</strong>Starting at 11:00 AM ET</div>
                <div><strong>Saturday</strong>Starting at 11:00 AM ET</div>
              </div>
              <Link className="button" to="/schedule">View Full Schedule</Link>
            </div>
            <div className="image-wrapper">
              <img src="https://webcdn.hirezstudios.com/esports/assets/what-is-2.png" alt="Splyce" />
            </div>
          </section>
          <section className="format">
            <strong>2022 LEAGUE FORMAT</strong>
            <p>The SMITE Challenger Circuit season is broken into three phases, with two cross-league Masters events at the end of Phases 1 and 2. At the end of the season, teams will also compete in a qualifier round for a seat in the pinnacle event of the year -- the SMITE World Championship.</p>
            <strong>REGULAR SEASON PLAY</strong>
            <p>Each phase in the SMITE Challenger Circuit will follow a single round robin format with best-of-three matches. Here’s full the roadmap for the season:</p>
            <div className="phase-container">
              <div><strong>• SCC Qualifiers --</strong> February 26 – March 20, 2022</div>
              <div><strong>• Phase 1 --</strong> March 26 – April 24, 2022</div>
              <div><strong>• SMITE Masters --</strong> May 19 – May 29, 2022</div>
              <div><strong>• Phase 2 --</strong> June 4 – July 3, 2022</div>
              <div><strong>• SCC-SOC Relegations --</strong> July 16, 2022</div>
              <div><strong>• SMITE Masters 2 --</strong> August 4 – August 14, 2022</div>
              <div><strong>• Phase 3 --</strong> August 20 – September 18, 2022</div>
              <div><strong>• SWC Wild Card Qualifiers --</strong> October 2, 2022</div>
              <div><strong>• SWC Qualifiers --</strong> October 28 – November 20, 2022</div>
            </div>
            <strong>SMITE MASTERS</strong>
            <p>Both of this year’s SMITE Masters events will be two-weeks long, cross-league LANs. Masters will follow 16 teams from both the SMITE Challenger Circuit (four from NA, four from EU) and the SMITE Pro League (all 8 teams).</p>
            <p>This event will be a prime opportunity for SCC teams to test their skills against the best SMITE players in the world, and help them lay the groundwork for qualifying to SWC or being promoted to the SPL in Season 10.</p>
            <div className="phase-container">
              <div><strong>• Spring SMITE Masters --</strong> May 20 - May 29, 2022</div>
              <div><strong>• Summer SMITE Masters --</strong> August 5 - August 14, 2022</div>
            </div>
            <p>More details and information on the SMITE Masters events will be announced later this season.</p>
          </section>
          <section className="teams" id="teamsSection">
            <div className="teams-container">
              <div className="team-heading">Smite Challenger Circuit 2022 Teams</div>
              {props.context.teams && props.context.teams.map(team => 
              <Link className="team" to={`/teams/${team.tag.toLowerCase()}`} key={team.name}><img src={`https://webcdn.hirezstudios.com/smite-esports/dev/teams/${team.tag}-70x70.png`} alt={team.name} /> {team.name}</Link>
              )}
            </div>
          </section>
        </div>
      </main>
    </PageWrapper>
  );
};

export default withContext(Leagues);
